import { createRouter, createWebHistory } from '@ionic/vue-router'
import Cookies from 'js-cookie'

const routes = [
	{
		path: '/',
		redirect: '/checkup/check'
	},
	{
		path: '/checkup',
		component: () => import('@/layouts/mobileDefault'),
		meta: { 
			requiresAuth: true,
			channel : '2'
		},
		children: [
			{
				path: '/checkup/:isState',
				name: 'checkup',
				props: true,
				component: () => import('@/views/main/Checkup')
			},{
				path: '/step/qr-scanner/:num',
				name: 'qr-scanner',
				component: () => import('@/views/main/QrScanner')
			},{
				path: '/step/inspection-info/:num',
				name: 'inspection-info',
				component: () => import('@/views/main/InspectionInfo')
			},
			{
				path: '/step/special-mapping/:num',
				name: 'special-mapping',
				component: () => import('@/views/main/SpecialMapping')
			},
			{
				path: '/step/survey/:num',
				name: 'survey',
				component: () => import('@/views/main/Survey')
			},
			{
				path: '/step/photos/:num',
				name: 'photos',
				component: () => import('@/views/main/Photos')
			},	
			{
				path: '/apply-detail/:num/:isState',
				name: 'apply-detail',
				component: () => import('@/views/main/ApplyDetail')
			}
		]
	},

	/* login */
	{
		path: '/login',
		name: 'login',
		meta: { requiresAuth: false },
		component: () => import('@/views/login/Login')
	},

	/* home appliances */
	{
		path: '/appliances/checkup',
		component: () => import('@/layouts/mobileDefault'),
		meta: { 
			requiresAuth: true,
			channel : '3'
		},
		children: [
			{
				path: '/appliances/checkup/:isState',
				name: 'appliances-checkup',
				props: true,
				component: () => import('@/views/appliances/Checkup')
			},{
				path: '/appliances/inspection-info/:num',
				name: 'appliances-inspection-info',
				component: () => import('@/views/appliances/InspectionInfo')
			},{
				path: '/appliances/survey/:num/:orderNum',
				name: 'appliances-survey',
				component: () => import('@/views/appliances/Survey')
			},{
				path: '/appliances/apply-detail/:num/:isState',
				name: 'appliances-apply-detail',
				component: () => import('@/views/appliances/ApplyDetail')
			},{
				path: '/appliances/survey-file/:num/:orderNum',
				name: 'appliances-survey-file',
				component: () => import('@/views/appliances/SurveyFile')
			}
		]
	},
	/* mobile */
	{
		path: '/mobile/checkup',
		component: () => import('@/layouts/mobileDefault'),
		meta: { 
			requiresAuth: true,
			channel : '1' 
		},
		children: [
			{
				path: '/mobile/checkup',
				name: 'mobile-checkup',
				props: true,
				component: () => import('@/views/mobile/Checkup')
			},
			{
				path: '/mobile/survey/:num',
				name: 'mobile-survey',
				props: true,
				component: () => import('@/views/mobile/Survey')
			},
			{
				path: '/mobile/surveyFile/:num',
				name: 'mobile-survey-file',
				props: true,
				component: () => import('@/views/mobile/SurveyFile')
			},			
			{
				path: '/mobile/saleApplication',
				name: 'mobile-sale-application',
				props: true,
				component: () => import('@/views/mobile/SaleApplication')
			},
			{
				path: '/mobile/performance',
				name: 'mobile-performance',
				props: true,
				component: () => import('@/views/mobile/Performance')
			}
		]
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, _, next) => {
	if(Cookies.get('memNum') === undefined){
		if(to.meta.requiresAuth){
			return next("/login")
		}else{
			return next()	
		}
	}else{
		return next()
	}
  })
  

export default router
