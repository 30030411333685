import request from '@/utils/request';
const USER_PATH = '/admin';

const postLogin = (data) => {
	return request({
		url: `${USER_PATH}/login/`,
		method: 'post',
		data
	});
};

const postLogout = () => {
	return request({
		url: `${USER_PATH}/logout/`,
		method: 'post'
	});
};

export { postLogin, postLogout };
