<template>
  <ion-app>
    <!-- <component :is="layout" /> -->
    <ion-router-outlet></ion-router-outlet>
  </ion-app>
</template>

<script>
/* import DefaultLayout from '@/layouts/default'
import EmptyLayout from '@/layouts/empty'
import { IonApp } from '@ionic/vue'
import { useRoute } from 'vue-router'
import { defineComponent, computed } from 'vue' */
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  /* components: {
    IonApp,
    DefaultLayout,
    EmptyLayout
  },
  setup() {
    const route = useRoute()
    const layout = computed(() => {
      
      let resultLayout = DefaultLayout
      if (route.fullPath.includes('/checkup')) {
        resultLayout = EmptyLayout
      } else if (route.fullPath.includes('/qr-scanner')) {
        resultLayout = EmptyLayout
      } else if (route.fullPath.includes('/inspection-info')) {
        resultLayout = EmptyLayout
      } else if (route.fullPath.includes('/special-mapping')) {
        resultLayout = EmptyLayout
      } else if (route.fullPath.includes('/survey')) {
        resultLayout = EmptyLayout
      } else if (route.fullPath.includes('/photos')) {
        resultLayout = EmptyLayout
      } else {
        resultLayout = DefaultLayout;
      }

      return resultLayout
    })

    return {
      layout
    }
  } */
})
</script>