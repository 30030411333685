export const Qrcode = {
	namespaced: true,

	state: () => ({
		qrcodeInfo: {}
	}),

	mutations: {
		SET_QRCODE: (state, data) => {
			state.qrcodeInfo = data
		}
	},

	actions: {
		// QR code 데이터 저장
		saveQrcode({ commit }, payload) {
			commit('SET_QRCODE', payload)
		}
	}
}
