import { createStore } from 'vuex';
import { User } from '@/store/modules/User';
import { Qrcode } from '@/store/modules/Qrcode';
import createPersistedState from 'vuex-persistedstate'

export default createStore({
	modules: { 
		User, 
		Qrcode 
	},
	plugins: [
		createPersistedState({
			paths: ['Qrcode']
		})
	]
});
